import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//import winterAreal from "./winter_areal.min.jpg";

import gyCardWinter2021 from "../Promotion/Goodyear/gy_card_winter_2021.min.jpg";
import duCardWinter2021 from "../Promotion/Dunlop/du_card_winter_2021.min.jpg";
import fuCardWinter2021 from "../Promotion/Fulda/fu_card_winter_2021.min.jpg";
import gyLogo from "../Promotion/Goodyear/gy_h_logo.min.png";
import duLogo from "../Promotion/Dunlop/du_h_logo.min.png";
import fuLogo from "../Promotion/Fulda/fu_h_logo.min.png";
import gyCleaningSet from "./gy_cleaning_set copy.min.png";
import VoziVulcoVoucherHand from "../Promotion/VoziVulcoVoucher/vozzi_vulco_voucher3.min.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold + "!important",
  },
  boxPromotion: {
    width: "100%",
    background: theme.palette.box.stretched.background,
    pointerEvents: "none",
    textAlign: "center",
  },
  containerPromotion: {},
  card: {
    background: theme.palette.card.background.default,
    "&:hover": {
      background: theme.palette.card.background.hover,
    },
  },
}));

export default function Home() {
  const history = useHistory();
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        marginTop={{
          xs: 5,
          sm: 3,
          md: 7,
        }}
        mb={3}
      >
        <Card
          className={styles.card}
          elevation={0}
          onClick={() => history.push("/podarok")}
          sx={{ borderRadius: 0 }}
        >
          <CardActionArea>
            <Box
              height={{
                xs: "30rem",
                sm: "28rem",
                md: "33rem",
              }}
              sx={{
                backgroundColor: "rgb(0,73,169)",
                background:
                  "radial-gradient(circle, rgba(0,73,169,1) 51%, rgba(0,29,169,1) 100%)",
                cursor: "pointer",
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={{ xs: 0, sm: -2, md: 0 }}
                pt={{ xs: 11, sm: 15, md: 12 }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 210, sm: 300, md: 430 },
                    width: { xs: 250, sm: 340, md: 490 },
                  }}
                  src={gyCleaningSet}
                  alt="Goodyear Winter Range 2021"
                  pl={{ xs: 0, sm: 5, md: 0 }}
                />
                <Box>
                  <Typography
                    //variant="h2"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: "1",
                      fontSize: {
                        xs: "2.125rem",
                        sm: "3rem",
                        md: "3.75rem",
                      },
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                    }}
                    pr={{ xs: 0, sm: 5, md: 6 }}
                    color="primary.contrastText"
                    gutterBottom
                  >
                    <em>
                      Земи си и
                      <Box
                        component="span"
                        sx={{
                          color: "secondary.main",
                        }}
                      >
                        <br />
                        подарок
                      </Box>
                    </em>
                  </Typography>
                  <Typography
                    variant="h5"
                    color="primary.contrastText"
                    align="left"
                    mt={{ xs: 2, sm: 0 }}
                    sx={{
                      fontWeight: "bold",
                      textAlign: {
                        xs: "center",
                        sm: "left",
                      },
                    }}
                    gutterBottom
                  >
                    <em>Goodyear сет за чистење</em>
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </CardActionArea>
        </Card>
      </Box>

      <Container maxWidth={"xl"}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => history.push("/promo/goodyear/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={gyCardWinter2021}
                  alt="Goodyear Winter Range 2021"
                />
                <Box bgcolor="primary.main">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={gyLogo} alt="Goodyear Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Подарок ќебе со Goodyear гуми
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vinga of Sweden ќебе со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => history.push("/promo/dunlop/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={duCardWinter2021}
                  alt="Dunlop Winter Range 2021"
                />
                <Box bgcolor="secondary.main">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={duLogo} alt="Dunlop Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>

                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Подарок ќебе со Dunlop гуми
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Vinga of Sweden ќебе со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} lg={4}>
            <Card
              className={styles.card}
              elevation={0}
              onClick={() => history.push("/promo/fulda/winter")}
            >
              <CardActionArea>
                <CardMedia
                  component="div"
                  sx={{ height: { xs: 290, sm: 250, md: 328 } }}
                  image={fuCardWinter2021}
                  alt="Fulda Winter Range 2021"
                />
                <Box bgcolor="primary.contrastText">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box py={1}>
                      <img src={fuLogo} alt="Fulda Logo" height={50} />
                    </Box>
                  </Stack>
                </Box>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Подарок кабли со Fulda гуми
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Goodyear LED кабли со 4 гуми од 16" и поголеми
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

// <FontAwesomeIcon icon={faBars} />
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBars } from "@fortawesome/pro-solid-svg-icons";

/**
 
      <Box
        className={styles.boxQuote}
        paddingY={{ xs: 5, sm: 6 }}
        marginY={{ xs: 6 }}
      >
        <Container maxWidth={"xl"} className={styles.containerQuote}>
          <Typography
            className={styles.title}
            variant={"h4"}
            component={"h1"}
            //fontWeight={"bold"}
            mt={3}
          >
            Побарај понуда...
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button size="large" variant="contained" disableElevation>
              Понуда за гуми
            </Button>

            <Button size="large" variant="contained" disableElevation>
              Понуда за сервис
            </Button>
          </Stack>
        </Container>
      </Box>
 */
