import React from "react";
import { Box, Container, Grid, Stack, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast, faTools } from "@fortawesome/pro-solid-svg-icons";

import vulcoTollFreeNumber from "./vulco_tollfree_rgb.svg";

const useStyles = makeStyles((theme) => ({
  footerMain: {
    width: "100%",
    background: theme.palette.background.paper,
    //pointerEvents: "none",
    //textAlign: "center",
  },
  text: {
    width: "auto",
  },
  buttonCopytight: {
    pointerEvents: "none",
    color: theme.palette.text.secondary + " !important",
    "&:disabled": {
      color: theme.palette.text.secondary + " !important",
    },
  },
  buttonPolicy: {
    //float: "right",
    background: theme.palette.background.default + " !important",
    color: theme.palette.text.secondary + " !important",
    "&:disabled": {
      color: theme.palette.text.secondary + " !important",
    },
  },

  footerContact: {
    width: "100%",
    background: theme.palette.primary.main,
    //pointerEvents: "none",
    //textAlign: "center",
  },

  footerTitleHighlight: {
    fontWeight: theme.typography.fontWeightBold + "!important",
    color: theme.palette.primary.contrastText,
  },
  footerBoxImage: {
    marginRight: 15,
  },
  footerBoxHighlight: {
    width: "100%",
    background: theme.palette.primary.main,
    pointerEvents: "none",
    textAlign: "center",
  },

  button: {
    float: "right",
    color: theme.palette.primary.contrastText + "!important",

    "&::disabled": {
      color: theme.palette.primary.contrastText + "!important",
    },
  },
  buttonDisabled: {},
}));

export default function Footer(footer) {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box marginTop={{ xs: 20 }} component={"footer"}>
        <Box
          className={styles.footerBoxHighlight}
          paddingTop={{ xs: 4.5 }}
          paddingBottom={{ xs: 1.3 }}
          //marginBottom={{ xs: 3 }}
        >
          <Stack
            spacing={2}
            justifyContent="center"
            alignItems="center"
            direction={{
              xs: "column",
              md: "row",
            }}
          >
            <Box>
              <img
                className={styles.footerBoxImage}
                src={vulcoTollFreeNumber}
                alt="Vulco Prile 0800 VULCO (88526)"
                height={62}
              />
              <Typography
                className={styles.footerTitleHighlight}
                variant={"button"}
                component={"h6"}
                my={2}
                mt={-1}
                px={4}
                color="secondary"
              >
                <em>БЕСПЛАТЕН ПОВИК—ВЕ ОЧЕКУВАМЕ!</em>
              </Typography>
            </Box>
            <Box>
              <Stack
                direction="column"
                justifyContent="flex-end"
                alignItems={{ xs: "center", md: "flex-start" }}
                spacing={0}
                mb={2}
              >
                <Button
                  size="large"
                  classes={{
                    root: styles.button,
                    disabled: styles.buttonDisabled,
                  }}
                  startIcon={
                    <FontAwesomeIcon icon={faShippingFast} fixedWidth />
                  }
                >
                  БЕСПЛАТНА ДОСТАВА ПО КАРГО
                </Button>
                <Button
                  disabled
                  size="large"
                  classes={{
                    root: styles.button,
                    disabled: styles.buttonDisabled,
                  }}
                  startIcon={<FontAwesomeIcon icon={faTools} fixedWidth />}
                >
                  50% ПОПУСТ ЗА МОНТАЖА НА ГУМИ
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Box>

        <Box
          className={styles.footerMain}
          paddingTop={{ xs: 4, sm: 5 }}
          paddingBottom={{ xs: 6, sm: 7 }}
          marginTop={0}
        >
          <Container maxWidth={"xl"}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <Button
                  className={styles.buttonCopytight}
                  disabled
                  disableElevation
                >
                  ©{new Date().getFullYear()} UNIROYAL CO. DOOEL
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack
                  direction="row"
                  justifyContent={{ xs: "start", md: "end" }}
                >
                  <Button
                    className={styles.buttonPolicy}
                    variant="contained"
                    disableElevation
                    name="close_dialog"
                    onClick={footer.handlePolicyDialog}
                  >
                    ПОЛИТИКА НА ПРИВАТНОСТ
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
}

//, md: 75, lg: 75, xl: 75

/**
 * 
 * 
 * 
 * 
 * 
 *    <Box
        className={styles.footerContact}
        //paddingTop={{ xs: 4, sm: 5 }}
        //paddingBottom={{ xs: 6, sm: 7 }}
        //marginTop={{ xs: 20 }}
        component={"div"}
      >
        <Container maxWidth={"xl"}>
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              <List>
                <ListItem>
                  <ListItemText primary="Адреса" />
                  <Typography variant={"body1"}>
                    Александар Македонски 428
                    <br />
                    7500, Прилеп
                  </Typography>
                </ListItem>

                <ListItem>
                  <ListItemText primary="Работно време" secondary="" />
                  <Typography variant={"body1"}>
                    Понеделник—Сабота <br />
                    од 8:00 до 19:00
                  </Typography>
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack
                direction="row"
                justifyContent={{ xs: "start", md: "end" }}
              >
                <Button
                  className={styles.buttonPolicy}
                  variant="contained"
                  disableElevation
                >
                  ПОЛИТИКА НА ПРИВАТНОСТ
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Box>
 */
