import React from "react";

import {
  Box,
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Stack,
  CardMedia,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faCheck,
  faInfoCircle,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";

import snowQuizWriting from "./snow_quiz_writing.svg";
import gyLogo from "../Promotion/Goodyear/gy_h_logo.min.png";

export default function GoodyearSnowQuiz() {
  return (
    <React.Fragment>
      <Box
        height={{
          xs: "30rem",
          sm: "28rem",
          md: "33rem",
        }}
        mt={{
          xs: 5,
          sm: 3,
          md: 7,
        }}
        mb={17}
        sx={{}}
      >
        <CardMedia
          component="video"
          image={
            "https://player.vimeo.com/external/630016480.hd.mp4?s=2f9045c541b9ab5ef6828bc9d4d8470c574d9bf6&profile_id=175&oauth2_token_id=57447761"
          }
          autoPlay
          preload={"auto"}
          muted={"true"}
          loop={"true"}
          playsInline={"true"}
          poster={
            "https://images.pexels.com/videos/9871024/pexels-photo-9871024.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200"
          }
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        ></CardMedia>

        <CardMedia
          component={"img"}
          src={snowQuizWriting}
          alt="Снежен Квиз"
          sx={{
            position: "absolute",
            top: 0,
            left: "50%",
            height: { xs: "290px", md: "360px" },
            marginTop: { xs: 19, sm: 17, md: 20 },
            marginLeft: { xs: "-195px", md: "-235px" },
            width: "auto",
            padding: 0,
            margin: 0,
          }}
        ></CardMedia>
        <Box
          paddingTop={{ xs: 2.5 }}
          paddingBottom={{ xs: 1.6 }}
          sx={{
            marginTop: "-1px",
            width: "100%",
            background: (theme) => theme.palette.primary.main,
            pointerEvents: "none",
            textAlign: "center",
          }}
        >
          <Container maxWidth={"xl"}>
            <img src={gyLogo} alt="Goodyear Logo" height={70} />
          </Container>
        </Box>
      </Box>

      <Container maxWidth={"md"}>
        <Paper elevation={0}>
          <Typography variant="h5" gutterBottom paragraph px={2} pt={3}>
            Точните одговори ќе ги објавиме на 29.01.2022!
          </Typography>
          <Accordion elevation={0} expanded={true}>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} fixedWidth />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"button"}>Правила и Услови</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={"body2"} paragraph>
                Организирано од Vulco Прилеп во соработка со The Goodyear Tire &
                Rubber Company.
              </Typography>
              <Typography variant={"body2"} paragraph>
                1. Квизот ќе трае до 28.01.2022. А резултатите и победниците ќе
                бидат објавени наредниот ден на 29.01.2022.
              </Typography>
              <Typography variant={"body2"} paragraph>
                2. Може да учествуавш само доколку имаш наполнети 18+ години.
              </Typography>

              <Typography variant={"body2"} paragraph>
                3. Процес на избирање:
                <br />
                3.1. Ќе бидат избрани првите 10 учесници кои точно ќе одговорат
                на сите 6 прашања.
                <br />
                3.2. Во случај да нема доволно учесници што точно ги одговориле
                сите прашања остатокот од победниците ќе се бира од тие со
                највеќе точни одговори.
                <br /> 3.3. Одговорите при изборот ќе бидат подредени од прв до
                последен по датум, изборот почнува од првите—најстарите.
              </Typography>
              <Typography variant={"body2"} paragraph>
                4. Промотивниот подарок не може да се замени за готовина или
                друг производ.
              </Typography>
              <Typography variant={"body2"} paragraph>
                5. Промотивниот подарок ќе биде испратен по карго (на трошок на
                организаторот) доколку учесникот не се наоѓа во Прилеп.
              </Typography>
              <Typography variant={"body2"} paragraph>
                6. Со пополнување на квизот се согласуваш да добиваш промотивни
                известувања и директен маркетинг.
              </Typography>
              <Typography variant={"body2"} paragraph>
                7. За начиност на чување и процесирање на продатоците прочитај
                ја Политиката на Приватност.
              </Typography>
              <Typography variant={"body2"} paragraph>
                Доколку во било кој момент не сакаш да добиваш известувања,
                директен маркетинг или пак сакаш да ја повлечеш согласноста или
                да ти ги избришеме податоците пиши ни на e-mail адресата
                објавена во Политиката на Приватност.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

/***8
 * 
 * 
 * 
 * 
 * 
 *  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Роденден"
                      inputFormat="dd/MM/yyyy"
                      //value={value}
                      //onChange={handleChange}
                      //renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>



        <video

          preload="auto"
          muted="true"
          loop="true"
          autoplay="autoplay"
          playsInline=""
          poster="https://images.pexels.com/videos/9871024/pexels-photo-9871024.jpeg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200"
        >
          <source
            type="video/mp4"
            src="https://player.vimeo.com/external/630016480.hd.mp4?s=2f9045c541b9ab5ef6828bc9d4d8470c574d9bf6&profile_id=175&oauth2_token_id=57447761"
          />
        </video>
 */
