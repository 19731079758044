import React from "react";

import {
  Box,
  Container,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  CardMedia,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faRetweet,
  faBullhorn,
  faSearch,
} from "@fortawesome/pro-solid-svg-icons";

import VoziVulcoVoucherHand from "./vozzi_vulco_voucher3.min.png";

export default function VoziVulcoVoucher() {
  return (
    <React.Fragment>
      <Box
        minHeight={"auto"}
        mt={{
          xs: 5,
          sm: 3,
          md: 7,
        }}
        mb={3}
        sx={{
          backgroundColor: "rgb(0,73,169)",
          background:
            "radial-gradient(circle, rgba(0,73,169,1) 51%, rgba(0,29,169,1) 100%)",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent="center"
          alignItems="flex-end"
          spacing={0}
          pt={{ xs: 6, sm: 8, md: 5 }}
          pl={{ xs: 0, sm: 3, md: 5 }}
        >
          <CardMedia
            component="div"
            sx={{
              height: { xs: 240, sm: 370, md: 430 },
              width: { xs: 380, sm: 565, md: 700 },
              //backgroundColor: "red",
            }}
            image={VoziVulcoVoucherHand}
            alt="Vulco + Vozi Voucher"
            //mt={{ sm: 5, md: 0 }}
          />
        </Stack>
      </Box>

      <Container maxWidth={"md"}>
        <Paper elevation={0}>
          <Box mx={2} py={2}>
            <Typography variant={"h5"} fontWeight={500} gutterBottom>
              Vulco и Vozi Ве наградуваат!
            </Typography>
            <Typography variant={"body1"} fontWeight={"normal"} gutterBottom>
              Искористи ја шансата да добиеш{" "}
              <Box component="span" fontWeight={500}>
                ваучер во вредност од 15,000 денари
              </Box>{" "}
              кој можеш да го искористиш за производи и услуги во Vulco Прилеп.
            </Typography>

            <List
              subheader={
                <Typography variant={"body1"} fontWeight={"normal"}>
                  Како да учествуваш:
                </Typography>
              }
            >
              <ListItem>
                <ListItemIcon
                  sx={{ color: (theme) => theme.palette.list.icon }}
                >
                  <FontAwesomeIcon icon={faBullhorn} fixedWidth />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box component="span">
                      Објави оглас на{" "}
                      <Link
                        underline="none"
                        href={"https://vozi.com"}
                        target="_blank"
                        rel="noopener"
                      >
                        vozi.com
                      </Link>
                    </Box>
                  }
                  secondary={"Во периодот од 24.11.2021 до 15.12.2021"}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{ color: (theme) => theme.palette.list.icon }}
                >
                  <FontAwesomeIcon icon={faRetweet} fixedWidth />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box component="span">
                      Сподели ја објавата од{" "}
                      <Link
                        underline="none"
                        href={
                          "https://www.facebook.com/vozicom/posts/405939647849933"
                        }
                        target="_blank"
                        rel="noopener"
                      >
                        Facebook
                      </Link>{" "}
                      (јавно)
                    </Box>
                  }
                  //secondary={"Во периодот од 24.11.2021 до 15.12.2021"}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{ color: (theme) => theme.palette.list.icon }}
                >
                  <FontAwesomeIcon icon={faRetweet} fixedWidth />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box component="span">
                      Или сподели ја{" "}
                      <Link
                        underline="none"
                        href={"https://www.instagram.com/p/CWqM_9oMq7O/"}
                        target="_blank"
                        rel="noopener"
                      >
                        објавата од Instagram
                      </Link>{" "}
                    </Box>
                  }
                  secondary={
                    "На story—тагнете и заследете ги профилите @vozi_com и @vulco.pp"
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon
                  sx={{ color: (theme) => theme.palette.list.icon }}
                >
                  <FontAwesomeIcon icon={faSearch} fixedWidth />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box component="span">
                      Добитникот ќе биде избран по случаен избор
                    </Box>
                  }
                  secondary={"На 16.12.2021 во 14:00ч"}
                />
              </ListItem>
            </List>
          </Box>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<FontAwesomeIcon icon={faChevronDown} fixedWidth />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant={"button"}>Правила и Услови</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant={"body2"} paragraph>
                1. Право на учество има секој со наполнети 18 години кој ги
                исполнува горенаведените услови.
              </Typography>
              <Typography variant={"body2"} paragraph>
                2. Ваучерот ќе биде испратен по пошта од страна на Vulco Прилеп.
                <br />
                2.1. Истиот може да се употреби само во Vulco Прилеп.
                <br />
                2.2. Вредносниот ваучер мора да се употреби наеднаш—во целост до
                датумот означен на здната страна.
                <br />
                2.2.1. Во случај по употребата да има преостаната сума—истата
                пропаѓа; и неможе да се употреби покасно.
                <br />
                2.3. Ваучерот неможе да се пренеси на друго лице.
              </Typography>
              <Typography variant={"body2"} paragraph>
                3. Извлекувањето ќе биде спроведено од тимот на vozi.com по
                случаен избор.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

/***8
 * 
 * 
 * 
 * 
 * 
 *  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Роденден"
                      inputFormat="dd/MM/yyyy"
                      //value={value}
                      //onChange={handleChange}
                      //renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
 */
