import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";

import VulcoLogo from "./vulco.svg";

const useStyles = makeStyles((theme) => ({
  appbar: {
    position: "sticky",
  },
  toolbar: {
    display: "flex",
  },

  logo: {
    position: "fixed",
    top: 0,
    pointerEvents: "none",
    shadows: 2,
    dropShadow: 20,
    width: "100%",
  },
  drawerPaper: {
    background: theme.palette.background.default + "!important",
  },
  location: {
    float: "right",
    color: theme.palette.primary.contrastText + "!important",

    "&::disabled": {
      color: theme.palette.primary.contrastText + "!important",
    },
  },
  locationDisabled: {},
}));

export default function Header() {
  const history = useHistory();

  const [drawer, setDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(open);
  };

  const styles = useStyles();

  return (
    <React.Fragment>
      <AppBar className={styles.appbar} elevation={0}>
        <Box
          component="img"
          className={styles.logo}
          src={VulcoLogo}
          height={{
            xs: 70,
            sm: 76,
          }}
          mt={{
            xs: "9px",
            sm: "13px",
          }}
        />
        <Toolbar className={styles.toolbar}>
          <Box display="flex" flexGrow={1}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
              onKeyDown={toggleDrawer(true)}
            >
              <FontAwesomeIcon icon={faBars} fixedWidth />
            </IconButton>
          </Box>

          <Button
            size="large"
            sx={{ display: { xs: "none", sm: "flex" } }}
            onClick={() => history.push("/contact")}
            classes={{
              root: styles.location,
              disabled: styles.locationDisabled,
            }}
            startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} fixedWidth />}
          >
            Прилеп
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"left"}
        open={drawer}
        onClose={toggleDrawer(false)}
        classes={{ paper: styles.drawerPaper }}
        elevation={0}
      >
        <Box
          role="presentation"
          width={220}
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={() => history.push("/")}>
              <ListItemText primary={"Почетна"} />
            </ListItem>
            <ListItem button onClick={() => history.push("/podarok")}>
              <ListItemText primary={"Земи Подарок"} />
            </ListItem>
            <ListItem button onClick={() => history.push("/contact")}>
              <ListItemText primary={"Контакт"} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

//, md: 75, lg: 75, xl: 75
