import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useLocation,
  withRouter,
  Redirect,
} from "react-router-dom";

import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  useMediaQuery,
  Backdrop,
  Snackbar,
  Link,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Home from "./Home/Home";
import Header from "./Header";
import Footer from "./Footer/Footer";

import AboutUs from "./AboutUs/AboutUs";
import ContactUs from "./ContactUs/ContactUs";

import gyBlanketWinter2021 from "./Promotion/Goodyear/BlanketWinter2021";
import duBlanketWinter2021 from "./Promotion/Dunlop/BlanketWinter2021";
import fuCablesWinter2021 from "./Promotion/Fulda/CablesWinter2021";
import CleaningSet2021 from "./Promotion/Gift/CleaningSet2021/CleaningSet2021";
import VoziVulcoVoucher from "./Promotion/VoziVulcoVoucher/VoziVulcoVoucher";
import GoodyearSnowQuiz from "./GoodyearSnowQuiz/GoodyearSnowQuiz";

//import "@fontsource/roboto/300.css";
//import "@fontsource/roboto/400.css";
//import "@fontsource/roboto/500.css";
//import "@fontsource/roboto/700.css";
//import "@fontsource/roboto/cyrillic-ext-300.css";
//import "@fontsource/roboto/cyrillic-ext-400.css";
//import "@fontsource/roboto/cyrillic-ext-500.css";
//import "@fontsource/roboto/cyrillic-ext-700.css";
import "@fontsource/rubik/300.css";
import "@fontsource/rubik/400.css";
import "@fontsource/rubik/500.css";
import "@fontsource/rubik/700.css";
//import "@fontsource/rubik/800.css";
import "@fontsource/rubik/900.css";
//import "@fontsource/rubik/cyrillic-ext-700.css";
//import "@fontsource/rubik/cyrillic-ext-800.css";
//import "@fontsource/rubik/cyrillic-ext-900.css";

const typography = {
  fontFamily: ["Rubik", "Helvetica", "Arial", "sans-serif"].join(","),
  h1: {
    fontWeight: 900,
  },
};

const primary = {
  //main: "#00309d",
  main: "rgb(0, 48, 157)",
  dark: "rgb(0, 33, 109)",
  light: "rgb(51, 89, 176)",
  //light: "rgb(0, 118, 179)",
};

const secondary = {
  //main: "#ffcd00",
  main: "rgb(255, 205, 0)",
  light: "rgb(255, 215, 51)",
  dark: "rgb(178, 143, 0)",
};

const text = {
  light: {
    primary: "rgba(0,0,0,0.87)",
    secondary: "rgba(0,0,0,0.6)",
    disabled: "rgba(0,0,0,0.38)",
  },
  dark: {
    primary: "rgb(255,255,255)",
    secondary: "rgba(255,255,255,0.6)",
    disabled: "rgba(255,255,255,0.38)",
  },
};

const themeLight = createTheme({
  typography: typography,
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: { ...primary, gradient: primary.light },
    secondary: { ...secondary, gradient: secondary.light },
    background: {
      default: "rgb(245, 245, 245)",
      paper: "#ffffff",
    },
    text: { ...text.light },
    divider: "rgba(0,0,0,0.12)",
    card: {
      background: {
        default: "#ffffff",
        hover: "rgba(200,200,200,0.1)",
      },
    },
    box: {
      stretched: {
        background: "transparent",
      },
    },
    accordion: {
      title: text.light.primary,
      icon: text.light.secondary,
    },
    list: {
      icon: text.light.secondary,
    },
    page: {
      name: {
        background: primary.light,
      },
    },
  },
});

const themeDark = createTheme({
  typography: typography,
  shape: {
    borderRadius: 6,
  },
  palette: {
    primary: { ...primary, gradient: primary.dark },
    secondary: { ...secondary, gradient: secondary.dark },
    background: {
      default: "#0d0d0d",
      //paper: "rgba(255, 255, 255, 0.1)",
      paper: "#262626",
    },
    text: { ...text.dark },
    divider: "rgba(255,255,255,0.12)",
    card: {
      background: {
        default: "#0d0d0d",
        hover: "rgba(0,0,0,0.02)",
      },
    },
    box: {
      stretched: {
        background: "transparent",
      },
    },
    accordion: {
      title: text.dark.primary,
      icon: text.dark.secondary,
    },
    list: {
      icon: text.dark.secondary,
    },
    page: {
      name: {
        background: primary.dark,
      },
    },
  },
});

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

export default function Portal() {
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
  const [state, setState] = useState({
    policyAccepted: false,
    policyDialogShow: false,
    policyDialogButtonClose: false,
  });

  useEffect(() => {
    if (
      !localStorage.getItem("vulcoPrivacyPolicyAccepted") &&
      !state.policyAccepted
    ) {
      setState({ ...state, policyAccepted: true });
    }
  }, [state]);

  const handlePolicyAcceptButton = (e) => {
    localStorage.setItem(
      "vulcoPrivacyPolicyAccepted",
      new Date().toISOString()
    );
    setState({
      ...state,
      policyAccepted: false,
      policyDialogShow: false,
    });
  };

  const handlePolicyDialogCloseButton = (e) => {
    setState({
      ...state,
      policyDialogShow: false,
    });
  };

  const handlePolicyDialog = (e) => {
    var dialogButtonClose = false;
    var dialogShow = true;
    var acceptShow = false;

    if (e.target.name === "close_dialog") {
      dialogButtonClose = true;
      dialogShow = true;
    } else if (e.target.name === "accept_dialog") {
      dialogButtonClose = false;
    } else {
      dialogButtonClose = true;
    }
    setState({
      ...state,
      policyAccepted: acceptShow,
      policyDialogButtonClose: dialogButtonClose,
      policyDialogShow: dialogShow,
    });
  };

  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={isDarkModeEnabled ? themeDark : themeLight}>
          <ScrollToTop>
            <CssBaseline />
            <Header />

            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={AboutUs} />
              <Route path="/contact" component={ContactUs} />
              <Route
                path="/promo/goodyear/winter"
                component={gyBlanketWinter2021}
              />
              <Route
                path="/promo/dunlop/winter"
                component={duBlanketWinter2021}
              />
              <Route
                path="/promo/fulda/winter"
                component={fuCablesWinter2021}
              />
              <Route path="/promo/vozi" component={VoziVulcoVoucher} />
              <Route path="/podarok" component={CleaningSet2021} />
              <Route path="/snow-quiz" component={GoodyearSnowQuiz} />

              <Redirect to="/" />
            </Switch>

            <Footer handlePolicyDialog={handlePolicyDialog} />
          </ScrollToTop>
          <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={state.policyAccepted}
          >
            <Snackbar
              sx={{ maxWidth: 600, marginRight: { xs: "inherit", sm: "24px" } }}
              open={state.policyAccepted}
              message={
                <Typography variant="body1">
                  Страната користи колачиња (cookies) за правилно да работи. За
                  да продолжите потребно е да ја прифатите
                  <Link
                    underline="none"
                    variant="body1"
                    sx={{ cursor: "pointer" }}
                    name="accept_dialog"
                    onClick={handlePolicyDialog}
                  >
                    {" "}
                    политиката на приватност
                  </Link>
                  .
                </Typography>
              }
              action={
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handlePolicyAcceptButton}
                >
                  ПРИФАЌАМ
                </Button>
              }
            />
          </Backdrop>

          <Dialog
            open={state.policyDialogShow}
            //onClose={handleClose}
            scroll={"paper"}
            disableEscapeKeyDown
            maxWidth={"sm"}
          >
            <DialogTitle sx={{ textTransform: "uppercase" }}>
              Политика на приватност
            </DialogTitle>
            <DialogContent dividers={true}>
              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                pt={3}
                color="text.secondary"
              >
                Со цел подобро да го разберете процесот на собирање на вашите
                лични податоци, нивното обработување и користењето колачиња
                (cookies), можете да ја прочитате изјавата за приватност која
                следи.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                Ве молиме внимателно прочитајте ја изјавата за да продолжите да
                ја користите оваа веб-страница. Ако не се согласувате со
                условите престанете да ја користите оваа веб-страница.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  1. Општи информации
                  <br />
                </Typography>
                Оваа веб-страница е во сопственост на УНИ-РОЈАЛ КОМПАНИ ДООЕЛ,
                Александар Македонски 428, 7500, Прилеп, Република Северна
                Македонија. Со единствен матичен број: 5299942 и единствен
                даночен број: MK4021998126195. Понатаму во текстот како
                ПРОВАЈДЕР. Изјавата за приватност за веб-страницата е применлива
                од 30.11.2021. Изјавата за приватност се однесува на прибирањето
                и процесирањето на вашите лични податоци и користењето колачиња.
                Со користење веб-страницата, се согласувате со методот на
                собирање и процесирање на податоците опишани во оваа изјава,
                како и со чување и пристапување до колачињата на вашиот уред.
                Оваа изјава опишува кои информации ги собираме, како ги
                обработуваме и за кои цели ги користиме, како и вашите права во
                однос на вашите податоци.
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  2. Собирање и обработка на податоците
                  <br />
                </Typography>
                Со внесување на личните податоци (име и презиме, E-mail, мобилен
                телефон, населно место на живеење и датум на раѓање) во полињата
                дадени за добивање ваучер по пат на SMS или E-mail, вие давате
                дозвола да се обработат тие податоци и да се користат за целта
                опишана во оваа изјава.
                <br />
                Доколку ја изберете опцијата за директен маркетинг, давате
                дозвола за да добивате промотивни известувања. Оваа дозвола
                можете во било кое време да ја отповикате.
                <br />
                Провајдерот собира и дополнителни информации за уредот преку кој
                пристапувате до веб-страницата. Податоците кои ги собираме ние
                се: вашата IP-адреса, информации за провајдерот на интернет
                пристап, видот на уред кој го користите за пристап, видот и
                верзијата на веб-пребарувачот, оперативниот систем, резолуцијата
                на мониторотот и информации за сесијата. Собраните податоци се
                обработуваат со цел обезбедување на поквалитетни услуги, подобра
                корисничка поддршка, полесен пристап со елиминирање на потребата
                за често внесување на истите податоци, таргетирани понуди и
                промоции за програмска содржина, како и за реклами.
                <br />
                Провајдерот на никаков начин не обезбедува информации за
                корисниците кои се регистрирале за ваучер на трети страни. Со
                обврската за задржување на доверливоста, Провајдерот може да им
                дозволи на сигурни партнери кои имаат договорна обврска да ја
                сочуваат доверливоста, пристап до неопходните лични податоци со
                цел да се обезбеди функционалноста на платформата за
                обезбедување на услугата и за корисничка поддршка. На секоја
                таква трета страна и е забранета понатамошна обработка на вашите
                лични податоци.
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  3. Безбедност на вашите лични податоци
                  <br />
                </Typography>
                Податоците на серверите се чуваат во контролирана, безбедна
                околина, заштитена од неавторизиран пристап, користење или
                разоткривање. Во комуникација меѓу уредите, податоците се
                енкриптираат за услугата да се доведе до повисоко ниво на
                сигурност и приватност. Ние ги штитиме податоците со неколку
                безбедносни слоеви, меѓу кои и водечка HTTPS технологија на
                енкрипција.
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  4. Користење на колачиња
                  <br />
                </Typography>
                Веб-страницата користи таканаречени колачиња—сет податоци
                создадени од сервер и кои веб-пребарувачот ги сочувува на дискот
                на корисникот во облик на мала текстуална датотека. Датотеките
                се создаваат кога веб-пребарувачот ќе ја симне дестинацијата на
                посетената мрежа во уредот на корисникот, кој потоа ги испраќа
                податоците до веб-пребарувачот и создава текстуална датотека
                (колаче). Колачињата се користат за подобро корисничко искуство
                со целосната функционалност на апликацијата која се користи, и
                тие можат да бидат привремени (сочувани само во текот на
                посетите на страниците) или трајни (остануваат на компјутерот на
                корисникот после посетата).
                <br />
                Провајдерот користи колачиња од трети страни за:
                <br />- Добивање статистички податоци за посетите и користењето
                на нашата апликација. Собраните податоци вклучуваат IP-адреси од
                корисниците, информации за веб-пребарувачот и оперативниот
                систем, како и други стандардни податоци собрани и анализирани
                исклучиво во анонимна и масовна форма. Веб-страницата користи
                статистики од Google Analytics, а за политики на колачиња од
                трети страни видете на:{" "}
                <Link
                  href="https://www.google.com/analytics/terms/us.html"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  Google Analytics
                </Link>
                , и Facebook Pixel, за нивните политики за колачиња од трети
                страни прочитајте повеќе тука:{" "}
                <Link
                  href="https://www.facebook.com/policies/cookies/"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  Facebook Pixel
                </Link>
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  5. Одбивање колачиња
                  <br />
                </Typography>
                Ако не се согласите со нивната употреба престанете да ја
                користите оваа веб-страница веднаш. А можете лесно да ги
                избришете колачињата од вашиот компјутер или мобилен уред со
                помош на поставките од веб-пребарувачот кој го користите.
                <br />
                За повеќе информации за справување со вашите колачиња, посетете
                ги страниците од веб-пребарувачот кој го користите или одете на:{" "}
                <Link
                  href="http://www.allaboutcookies.org/"
                  target="_blank"
                  rel="noopener"
                  underline="none"
                >
                  All About Cookies
                </Link>
                <br />
                Бидејќи целта на колачињата е да се подобри и овозможи
                користењето на веб-страницата и нејзините процеси, запаметете
                дека со одрекување или бришење на колачињата, можете да го
                онеспособите функционирањето на некои алатки или да предизвикате
                тие да работат и да изгледаат поинаку во вашиот веб-пребарувач.
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  6. Период на чување
                  <br />
                </Typography>
                Ние ќе ги избишеме вашите лични податоци при вашето барање или
                најдоцна по десет години, и истекувањето на сите законски
                обврски поврзани со чувањето лични податоци. Кога се бришат, дел
                од податоците стануваат анонимни, и трајно се отстрануваат сите
                врски со одредена личност.
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  7. Права на корисникот
                  <br />
                </Typography>
                Во исто време, ве информираме дека имате право да пристапите до
                сите ваши дадени лични податоци, имате право на нивно
                коригирање, и право да ја отповикате претходно дадената
                согласност.
                <br />
                Ако ги отповикате дадените согласности, ние ќе ги избришеме
                вашите лични податоци, но вие веќе нема да бидете во можност да
                ја ги користите ваучерите.
                <br />
                Ако сакате повторно да дадете ваша согласност, можете да го
                направите тоа со повторно аплицирање за ваучер.
                <br />
                Одповикувањето се врши по пат на електронска пошта, со испраќање
                на барање од E-mail адресата која е регистрирана во нашиот
                систем до E-mail адресата наведена во став 9 од оваа изјава.
                Провајдерот во рок од 30 дена ќе постапи по барањето доколку
                може да автентикува дека испраќачот на баранњето се соодвестува
                со податоците во системот. Доколку корисникот нема пристап до
                адресата која ја внесил во системот, нема да може да се направи
                отповикување.
              </Typography>

              <Typography
                variant="body1"
                gutterBottom
                paragraph
                px={2}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  8. Услови и промени
                  <br />
                </Typography>
                Условите на оваа изјава за приватност го определуваат
                користењето на колачињата и сите податоци собрани за време на
                периодот за кој се однесува изјавата, со исклучок на колачињата
                од трети страни. Провајдерот го задржува правото да ја промени
                оваа изјава и правилата за колачиња во секое време, без
                претходно известување.
              </Typography>

              <Typography
                variant="body1"
                paragraph
                px={2}
                pb={3}
                color="text.secondary"
              >
                <Typography variant="button" component="span">
                  9. Контакт информации
                  <br />
                </Typography>
                Провајдерот со задоволство ќе ги прими вашите коментари и
                сугестии во врска со оваа изјава, како и вашите прашања за
                вашите права како корисник на оваа страница. Можете да ни ги
                испратите вашите коментари и сугестии на: privacy (plus) vulco
                (at) uniroyal (dot) mk
              </Typography>
            </DialogContent>
            <DialogActions>
              {!state.policyDialogButtonClose ? (
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handlePolicyAcceptButton}
                >
                  ПРИФАЌАМ
                </Button>
              ) : (
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handlePolicyDialogCloseButton}
                >
                  ЗАТВОРИ
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

/*     <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingY="10px"
              >
                
              </Box>


              <GlobalStyles
          styles={{
            body: { margin: 0 },
          }}
        />


                <Box sx={{ flexGrow: 1 }}></Box>

 */
