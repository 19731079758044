import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  MenuItem,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleHighlight: {
    fontWeight: theme.typography.fontWeightBold + "!important",
    color: theme.palette.primary.contrastText,
  },
  boxPageName: {
    width: "100%",
    background: theme.palette.page.name.background,
    pointerEvents: "none",
    textAlign: "center",
    textTransform: "uppercase",
  },
  accordionSummaryTitle: {
    color: theme.palette.accordion.title,
  },
  accordionSummaryIcon: {
    color: theme.palette.accordion.icon,
  },
  mapButton: {
    background: theme.palette.devider,
    color: "red",
  },
}));

const country = {
  MK: {
    prefix: "389",
    lengthMin: 8,
    lengthMax: 8,
    localPrefix: [
      {
        prefix: "2",
        accepted: true,
        helper: "02 XXXX XXX",
      },
      {
        prefix: "3",
        accepted: false,
        helper: "03X XXX XXX",
      },
      {
        prefix: "31",
        accepted: true,
        helper: "031 XXX XXX",
      },
      {
        prefix: "32",
        accepted: true,
        helper: "032 XXX XXX",
      },
      {
        prefix: "33",
        accepted: true,
        helper: "033 XXX XXX",
      },
      {
        prefix: "34",
        accepted: true,
        helper: "034 XXX XXX",
      },
      {
        prefix: "4",
        accepted: false,
        helper: "04X XXX XXX",
      },
      {
        prefix: "42",
        accepted: true,
        helper: "042 XXX XXX",
      },
      {
        prefix: "43",
        accepted: true,
        helper: "043 XXX XXX",
      },
      {
        prefix: "44",
        accepted: true,
        helper: "044 XXX XXX",
      },
      {
        prefix: "45",
        accepted: true,
        helper: "045 XXX XXX",
      },
      {
        prefix: "46",
        accepted: true,
        helper: "046 XXX XXX",
      },
      {
        prefix: "47",
        accepted: true,
        helper: "047 XXX XXX",
      },
      {
        prefix: "48",
        accepted: true,
        helper: "048 XXX XXX",
      },
      {
        prefix: "7",
        accepted: false,
        helper: "07X XXX XXX",
      },
      {
        prefix: "70",
        accepted: true,
        helper: "070 XXX XXX",
      },
      {
        prefix: "71",
        accepted: true,
        helper: "071 XXX XXX",
      },
      {
        prefix: "72",
        accepted: true,
        helper: "072 XXX XXX",
      },
      {
        prefix: "73",
        accepted: true,
        helper: "073 XXX XXX",
      },
      {
        prefix: "74",
        accepted: true,
        helper: "074 XXX XXX",
      },
      {
        prefix: "75",
        accepted: true,
        helper: "075 XXX XXX",
      },
      {
        prefix: "76",
        accepted: true,
        helper: "076 XXX XXX",
      },
      {
        prefix: "77",
        accepted: true,
        helper: "077 XXX XXX",
      },
      {
        prefix: "78",
        accepted: true,
        helper: "078 XXX XXX",
      },
      {
        prefix: "79",
        accepted: true,
        helper: "079 XXX XXX",
      },
    ],
  },
};

function checkLocalPrefix(number, reference) {
  return reference
    .filter((item) => number.startsWith(item.prefix))
    .reduce(
      (previous, current) =>
        previous.prefix > current.prefix ? previous : current,
      {}
    );
}

function isEmail(email) {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

const initialData = {
  value: "",
  required: true,
  disabled: false,
  valid: false,
  error: false,
  message: "",
};

export default function ContactUs() {
  const [formName, setFormName] = useState(initialData);
  const [formPrefix, setFormPrefix] = useState(initialData);
  const [formPhone, setFormPhone] = useState(initialData);
  const [formEmail, setFormEmail] = useState(initialData);
  const [formSubject, setFormSubject] = useState(initialData);
  const [formMessage, setFormMessage] = useState(initialData);
  const [formAnswer, setFormAnswer] = useState(initialData);
  const [form, setForm] = useState({
    buttonDisabled: false,
  });
  const styles = useStyles();

  return (
    <React.Fragment>
      <Box
        className={styles.boxPageName}
        paddingTop={{ xs: 6, sm: 7 }}
        paddingBottom={{ xs: 5, sm: 6 }}
        marginTop={{
          xs: 7,
          sm: 8,
        }}
      >
        <Container maxWidth={"xl"}>
          <Typography
            className={styles.titleHighlight}
            variant={"h5"}
            component={"h1"}
            my={2}
          >
            Контакт
          </Typography>
        </Container>
      </Box>

      <Box
        marginTop={{
          xs: 7,
          sm: 8,
        }}
      >
        <Container maxWidth={"md"}>
          <Paper elevation={0}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  mx={{ xs: 3, md: 2 }}
                  mt={{ xs: 1, md: 1 }}
                  mb={{ xs: 0, md: 3 }}
                >
                  <List>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Vulco Прилеп"
                        primaryTypographyProps={{ variant: "h5" }}
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Работно Време"
                        primaryTypographyProps={{ variant: "button" }}
                        secondary={
                          <Typography component="span" variant="body1">
                            Понеделник—Сабота од 8:00 до 19:00
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Адреса"
                        primaryTypographyProps={{ variant: "button" }}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body1"
                              color="text.primary"
                            >
                              Александар Македонски 428
                              <br />
                              7500, Прилеп (МК)
                            </Typography>
                            <Box fullWidth mt={1}>
                              <Button
                                variant="contained"
                                className={styles.mapButton}
                                size="small"
                                disableElevation
                                href="https://g.page/vulco-pp"
                                target="_blank"
                              >
                                Отвори на мапа
                              </Button>
                            </Box>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Бесплатен Телефон"
                        primaryTypographyProps={{ variant: "button" }}
                        secondary={
                          <Typography component="span" variant="body1">
                            0800 VULCO (88526)
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Телефон"
                        primaryTypographyProps={{ variant: "button" }}
                        secondary={
                          <Typography component="span" variant="body1">
                            +389 (0)48 410 164
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>

      <Container maxWidth={"xl"}></Container>
    </React.Fragment>
  );
}

// Dynamic number formatter
/*
function numberFormatter(number, placeholder, a, b, c) {
  var regex = new RegExp(`(\\d{${a}})(\\d{${b}})(\\d{${c}})`);
  return number.replace(regex, placeholder);
}

                    <Divider component="li" />

*/
